<template>
  <mobile-screen :header="true" screen-class="profile-screen icon-app1">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="applications-header icon-hea1">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("countries", "countries", "countries") }}
        </div>
        <template v-slot:right>
          <router-link :to="{ name: 'r_add-country' }">
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
      <section class="search-wrapper coutries-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="displayLabelName('countries', 'countries', 'search')"
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
    </template>
    <ul class="named-list" v-if="listOfCountries && listOfCountries.length">
      <li class="name-wrapper">
        <p class="tiny-label bg">
          {{ displayLabelName("countries", "countries", "countries") }}
        </p>
        <ul class="bordered-list">
          <li
            class="list-item"
            v-for="country in listOfCountries"
            :key="country.id"
          >
            <div class="checkbox alt tiny block">
              <input
                type="radio"
                :id="`country${country.id}`"
                name="country"
                :selected="country.id === selectedCountry"
                :value="country.id"
                @change="selectCountry(country.id)"
              />
              <label :for="`country${country.id}`">
                <icon icon="#cx-app1-checkmark" />
                {{ country.name }}</label
              >
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              :disabled="!selectedCountry || processing"
              @click="editCountry"
            >
              {{ displayLabelName("countries", "countries", "select") }}
            </button>
          </li>
          <li class="action">
            <button
              class="action-btn"
              :disabled="!selectedCountry || processing"
              @click="show = true"
            >
              {{ displayLabelName("countries", "countries", "delete") }}
            </button>
          </li>
        </ul>
      </nav>
    </template>
    <screen-modal
      v-if="show"
      class="confirm-modal"
      type="success"
      :confirm-action="startDelete"
      :confirm-button-label="
        displayLabelName('countries', 'countries', 'delete-confirm-action')
      "
      :cancel-button-label="
        displayLabelName('countries', 'countries', 'delete-cancel-action')
      "
      :show="show"
      @close="show = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("countries", "countries", "delete-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("countries", "countries", "delete-action-text") }}
        <br />
        {{
          displayLabelName("countries", "countries", "delete-action-question")
        }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import { mapActions, mapState } from "vuex";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";

export default {
  name: "Countries",
  data() {
    return {
      selectedCountry: 0,
      processing: false,
      show: false,
      searchQuery: ""
    };
  },
  created() {
    this.getCountries();
  },
  computed: {
    ...mapState("country", ["countries"]),
    listOfCountries() {
      const query = this.searchQuery;
      if (this.countries && this.countries.data && this.countries.data.length) {
        if (query && query.length > 1) {
          return this.countries.data.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.countries.data;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("country", [
      "getCountries",
      "setSelectedCountry",
      "deleteCountry"
    ]),
    selectCountry(id) {
      this.selectedCountry = id;
    },
    editCountry() {
      this.setSelectedCountry(
        this.countries.data.filter(item => item.id === this.selectedCountry)
      );
      this.$router.push({ name: "r_edit-country" });
    },
    startDelete() {
      this.processing = true;
      this.deleteCountry(this.selectedCountry).finally(() => {
        this.processing = false;
        this.setSelectedCountry(null);
        this.$nextTick(() => {
          this.$router.push({ name: "r_countries" });
        });
      });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
